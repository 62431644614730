<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-5">Density of Liquids and Solids: Pre-Lab Questions</h2>

      <p class="mb-n3">
        a) If the directions in the lab manual told you to weigh out about
        <latex-number :number="valueA.toFixed(2)" unit="\text{g}" />
        of a chemical, but the balance reads
        <latex-number :number="valueA.toFixed(3)" unit="\text{g,}" />
        what would you record in your data table.
      </p>

      <v-radio-group v-model="inputs.massDecimalPlaces" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in options1"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <latex-number :number="option.text" unit="\text{g}" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-2">
        b) The density table in the lab manual says that the density of water at
        <latex-number :number="temp1" unit="^\circ\text{C}" />
        is
        <latex-number :number="density1" />
        while the density at
        <latex-number :number="temp2" unit="^\circ\text{C}" />
        is
        <latex-number :number="density2" />. What is the density at
        <latex-number :number="tempDensity.toFixed(1)" unit="^\circ\text{C?}" />
      </p>

      <calculation-input
        v-model="inputs.density"
        class="mb-5"
        prepend-text="$\text{Density:}$"
        append-text="$\text{g/mL}$"
        :disabled="!allowEditing"
      />

      <p class="mb-n3">
        c) <b> True or False: </b> When lighting a Bunsen burner, the gas supply valve on the bench
        should be all the way open and the one on the burner should be used to regulate the flame.
      </p>

      <v-radio-group v-model="inputs.flameAdjustment" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsTF"
          :key="'pt-3-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">
        d) <b> True or False: </b> Another student in your lab section made a mess on the balance
        and then walked away. You could get points deducted from your post-lab assignment because of
        this.
      </p>

      <v-radio-group v-model="inputs.dirtyBalance" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsTF"
          :key="'pt-4-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import NumberValue from '@/tasks/components/NumberValue';
import LatexNumber from '@/tasks/components/displayers/LatexNumber';
import CalculationInput from '@/tasks/components/inputs/CalculationInput';

export default {
  name: 'OleMissSyllabusQuiz',
  components: {NumberValue, StembleLatex, LatexNumber, CalculationInput},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        massDecimalPlaces: null,
        density: null,
        flameAdjustment: null,
        dirtyBalance: null,
      },
      optionsTF: [
        {text: '$\\text{True}$', value: 'true'},
        {text: '$\\text{False}$', value: 'false'},
      ],
    };
  },
  computed: {
    valueA() {
      return this.taskState.getValueBySymbol('valueA').content.toFloat();
    },
    options1() {
      return [
        {text: this.valueA.toFixed(0), value: '0DP'},
        {text: this.valueA.toFixed(1), value: '1DP'},
        {text: this.valueA.toFixed(2), value: '2DP'},
        {text: this.valueA.toFixed(3), value: '3DP'},
        {text: this.valueA.toFixed(4), value: '4DP'},
      ];
    },
    tempIncrement() {
      return this.taskState.getValueBySymbol('tempIncrement').content;
    },
    versionVariableB() {
      return this.taskState.getValueBySymbol('versionVariableB').content;
    },
    versionDataB() {
      switch (this.versionVariableB.value) {
        case 1:
          return {
            temp1: 15,
            density1: '0.999099\\,\\text{g/mL}',
            temp2: 16,
            density2: '0.998943\\,\\text{g/mL}',
          };
        case 2:
          return {
            temp1: 16,
            density1: '0.998943\\,\\text{g/mL}',
            temp2: 17,
            density2: '0.998774\\,\\text{g/mL}',
          };
        case 3:
          return {
            temp1: 17,
            density1: '0.998774\\,\\text{g/mL}',
            temp2: 18,
            density2: '0.998595\\,\\text{g/mL}',
          };
        case 4:
          return {
            temp1: 19,
            density1: '0.998405\\,\\text{g/mL}',
            temp2: 20,
            density2: '0.998203\\,\\text{g/mL}',
          };
        case 5:
          return {
            temp1: 20,
            density1: '0.998203\\,\\text{g/mL}',
            temp2: 21,
            density2: '0.997992\\,\\text{g/mL}',
          };
        case 6:
          return {
            temp1: 21,
            density1: '0.997992\\,\\text{g/mL}',
            temp2: 22,
            density2: '0.997770\\,\\text{g/mL}',
          };
        case 7:
          return {
            temp1: 22,
            density1: '0.997770\\,\\text{g/mL}',
            temp2: 23,
            density2: '0.997538\\,\\text{g/mL}',
          };
        case 8:
          return {
            temp1: 23,
            density1: '0.997538\\,\\text{g/mL}',
            temp2: 24,
            density2: '0.997296\\,\\text{g/mL}',
          };
        default:
          return {
            temp1: '0^\\circ\\text{C}',
            density1: '0\\,\\text{g/mL}',
            temp2: '0^\\circ\\text{C}',
            density2: '0\\,\\text{g/mL}',
          };
      }
    },
    temp1() {
      return this.versionDataB.temp1;
    },
    temp2() {
      return this.versionDataB.temp2;
    },
    density1() {
      return this.versionDataB.density1;
    },
    density2() {
      return this.versionDataB.density2;
    },
    tempDensity() {
      return this.temp1 + this.tempIncrement.toFloat();
    },
  },
};
</script>
<style scoped></style>
